<template>
  <v-container>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-application-user ref="search-application-user" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-local-unit ref="search-local-unit" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('masterdata_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('masterdata_reset')" /></v-btn>
    <v-btn color="secondary" @click="download"><span v-html="$t('download')" /></v-btn>

    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
    >
      <template v-slot:[`item.agateId`]="{ item }">
        <template v-if="item.userCount > 1">
          <router-link
            :to="{
              name: 'applicationUsers_read',
              query: { term: 'LegalEntity_uid:' + (item.legalEntityUid || '') }
            }"
          >
            <span v-html="$t('masterdata_many_users')"></span>
          </router-link>
        </template>
        <template v-else>
          {{ item.agateId }}
        </template>
      </template>

      <template v-slot:[`item.legalEntityUid`]="{ item }">
        <router-link
          :to="{
            name: 'masterdata_legal_entities_edit',
            params: { id: item.legalEntityId, tab: 'overview' }
          }"
        >
          {{ item.legalEntityUid && item.legalEntityUid.length > 0 ? item.legalEntityUid : $t('none') }}
        </router-link>
        <span class="link" v-if="$privileges.has({ path: '/domainSwitch', permission: 'read' })" @click="switchDomain(item)"
          ><v-icon right>mdi-chevron-right</v-icon></span
        >
      </template>

      <template v-slot:[`item.bur`]="{ item }">
        <template v-if="item.apiRoute == 'dairies'">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
          >
            {{ item.bur && item.bur.length > 0 ? item.bur : $t('none') }}
          </router-link>
        </template>
        <template v-if="item.apiRoute == 'farms'">
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
          >
            {{ item.bur && item.bur.length > 0 ? item.bur : $t('none') }}
          </router-link>
        </template>
      </template>
    </v-data-table-server>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import searchApplicationUser from '@/components/searchCards/searchApplicationUser.vue'
import { useSearchApplicationUserStore } from '@/store/SearchApplicationUserStore'
let searchApplicationUserStore = useSearchApplicationUserStore()
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLocalUnit from '@/components/searchCards/searchLocalUnit.vue'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import _ from 'lodash'
import { showError } from '@/services/axios'
import { fileCreator } from '@/services/axios'
import { personsService } from '@/services/persons'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'masterdata_localUnits_search',
  data() {
    return {
      dialog: false,
      items: [],
      initialized: false,
      loading: false,

      fields: <DTSHeader[]>[
        // Agate-Nr
        {
          title: this.$t('masterdata_table_header_agateid'),
          key: 'agateId',
          sortable: true,
          align: 'left'
        },
        // Name / Firma
        {
          title: this.$t('masterdata_table_header_name_company'),
          key: 'legalEntityName1',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('masterdata_table_header_name_addition'),
          key: 'legalEntityName2',
          sortable: true
        },
        // PLZ
        {
          title: this.$t('masterdata_table_header_zip'),
          key: 'zip',
          sortable: true,
          align: 'left'
        },
        // Ort
        {
          title: this.$t('masterdata_table_header_localty'),
          key: 'locality',
          sortable: true
        },
        // Info
        {
          title: this.$t('masterdata_table_header_info'),
          key: `info${this.$getUpLang()}`,
          sortable: true
        },
        // UID
        {
          title: this.$t('masterdata_table_header_uid'),
          key: 'legalEntityUid',
          sortable: true,
          align: 'left'
        },
        // BUR
        {
          title: this.$t('masterdata_table_header_ber'),
          key: 'bur',
          sortable: true,
          align: 'left'
        },
        // AGIS-ID Betrieb
        {
          title: this.$t('masterdata_table_header_agisid'),
          key: 'agisId',
          align: 'left',
          sortable: true
        },
        // MBH-ID
        {
          title: this.$t('masterdata_table_header_dairyIdent'),
          key: 'dairyIdent',
          sortable: true,
          align: 'left'
        }
      ],
      totalElements: 0
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(searchApplicationUserStore, searchApplicationUserStore.items),
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLocalUnitStore(), useSearchLocalUnitStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)
      ]
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions()
      }
    }
  },
  components: {
    searchApplicationUser,
    searchLegalEntity,
    searchFarm,
    searchContact,
    searchLocalUnit,
    searchDairy
  },
  methods: {
    search() {
      const term = Term.stringify(this.term)
      this.$router
        .push({
          path: this.$route.path,
          query: { term: term, ...this.getJavaPageOptions() }
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/masterdata/searchLocalUnit', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-application-user'].reset()
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-farm'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-local-unit'].reset()
      this.$refs['search-dairy'].reset()
    },
    async switchDomain(legalEntity) {
      personsService.switchDomain(legalEntity)
    },
    async download() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/masterdata/searchLocalUnit', {
          params: {
            ...this.getJavaPageOptions(),
            term: Term.stringify(this.term)
          },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'lokaleEinheiten.xlsx')
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.initialized = true
    if (this.$route.query.size) this.load()
  }
})
</script>
